import { getToken, logout } from '@services/auth'

const getUrl = (endpoint) => process.env.GATSBY_API_URL + endpoint

const getHeaders = ({ headers = {}, auth }) => {
	const token = getToken()
	if (auth && !token) logout()

	const defaults = {
		'Content-Type': 'application/json',
	}

	if (auth) defaults['Authorization'] = `Bearer ${token}`

	return {
		...defaults,
		...headers,
	}
}

const formatResponse = (response) => {
	if (
		response.statusCode === 401 ||
		response.statusCode === 403 ||
		response.error?.status === 401 ||
		response.error?.status === 403
	) {
		alert('Unauthorized. Please signin again.')
		return logout()
	}

	if (response.error) response.errorMessage = response.error.message
}

export const get = async (endpoint, auth) => {
	if (!getToken()) return logout()

	try {
		const response = await fetch(getUrl(endpoint), {
			method: 'GET',
			headers: getHeaders({ auth }),
		}).then((res) => res.json())

		formatResponse(response)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const post = async (endpoint, data, auth) => {
	try {
		const response = await fetch(getUrl(endpoint), {
			method: 'POST',
			headers: getHeaders({ auth }),
			body: JSON.stringify(data),
		}).then((res) => res.json())

		formatResponse(response)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const put = async (endpoint, data, auth) => {
	try {
		const response = await fetch(getUrl(endpoint), {
			method: 'PUT',
			headers: getHeaders({ auth }),
			body: JSON.stringify(data),
		}).then((res) => res.json())

		formatResponse(response)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const authGet = (endpoint) => get(endpoint, true)
export const authPost = (endpoint, data) => post(endpoint, data, true)
export const authPut = (endpoint, data) => put(endpoint, data, true)
