import React, { createContext, useState } from 'react'

import { isValidUser } from '@services/auth'

export const appContext = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
  responsiveHeader: false,
  setResponsiveHeader: () => {},
})

const { Provider } = appContext

const AppProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(isValidUser())
  const [responsiveHeader, setResponsiveHeader] = useState(false)

  return (
    <Provider
      value={{
        loggedIn,
        setLoggedIn,
        responsiveHeader,
        setResponsiveHeader,
      }}
    >
      {children}
    </Provider>
  )
}

export default AppProvider
