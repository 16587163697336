import { navigate } from 'gatsby'

import { post } from '@services/request'

export const isBrowser = () => typeof window !== 'undefined'
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('authUser')
    ? JSON.parse(window.localStorage.getItem('authUser'))
    : {}

export const getToken = () =>
  isBrowser() && window.localStorage.getItem('token')
    ? window.localStorage.getItem('token')
    : null

const setUser = ({ user, jwt }) => {
  window.localStorage.setItem('authUser', JSON.stringify(user))
  window.localStorage.setItem('token', jwt)
}

export const handleLogin = async ({ email, password }) => {
  const endpoint = '/auth/local'
  const data = {
    identifier: email,
    password,
  }

  const response = await post(endpoint, data)
  if (response.user) {
    const role = response.user.role.id
    if (role === 1) {
      setUser(response)
      return response
    } else if (role === 4) {
      return {
        error: true,
        errorMessage: 'Please login using your personal email.',
      }
    }
  }

  return response
}

export const handleForgotPassword = (data) => {
  const endpoint = '/auth/forgot-password'
  return post(endpoint, data)
}

export const handleResetPassword = async (data) => {
  const endpoint = '/auth/reset-password'
  const response = await post(endpoint, data)
  if (response.user) setUser(response)
  return response
}

export const isValidUser = () => {
  const user = getUser()
  return user && user.confirmed && !user.blocked
}

export const logout = () => {
  localStorage.clear()
  navigate('/signin')
}

export const contactUrl = `mailto:info@onpoint-nutrition.com`
