import React from 'react'

import '@styles/global.scss'

import AppProvider from '@src/store'

const addScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  script.id = 'hs-script-loader'
  script.defer = true
  document.body.appendChild(script)
}

const addClarityScript = function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      return (c[a].q = c[a].q || []).push(arguments)
    }
  t = l.createElement(r)
  t.async = 1
  t.src = 'https://www.clarity.ms/tag/' + i
  y = l.getElementsByTagName(r)[0]
  y.parentNode.insertBefore(t, y)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://js.hs-scripts.com/4326518.js')

    addClarityScript(window, document, 'clarity', 'script', 'fmzicrnnbq')
  }
}

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
)
