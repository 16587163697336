exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-account-forgot-password-js": () => import("./../../../src/templates/account/forgotPassword.js" /* webpackChunkName: "component---src-templates-account-forgot-password-js" */),
  "component---src-templates-account-register-js": () => import("./../../../src/templates/account/register.js" /* webpackChunkName: "component---src-templates-account-register-js" */),
  "component---src-templates-account-reset-password-js": () => import("./../../../src/templates/account/resetPassword.js" /* webpackChunkName: "component---src-templates-account-reset-password-js" */),
  "component---src-templates-account-signin-js": () => import("./../../../src/templates/account/signin.js" /* webpackChunkName: "component---src-templates-account-signin-js" */),
  "component---src-templates-account-whoops-js": () => import("./../../../src/templates/account/whoops.js" /* webpackChunkName: "component---src-templates-account-whoops-js" */),
  "component---src-templates-events-events-js": () => import("./../../../src/templates/events/events.js" /* webpackChunkName: "component---src-templates-events-events-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-offers-offers-js": () => import("./../../../src/templates/offers/offers.js" /* webpackChunkName: "component---src-templates-offers-offers-js" */),
  "component---src-templates-portions-portions-js": () => import("./../../../src/templates/portions/portions.js" /* webpackChunkName: "component---src-templates-portions-portions-js" */),
  "component---src-templates-recipe-list-recipe-list-js": () => import("./../../../src/templates/recipeList/recipeList.js" /* webpackChunkName: "component---src-templates-recipe-list-recipe-list-js" */),
  "component---src-templates-recipe-print-recipe-print-js": () => import("./../../../src/templates/recipePrint/recipePrint.js" /* webpackChunkName: "component---src-templates-recipe-print-recipe-print-js" */),
  "component---src-templates-recipe-recipe-js": () => import("./../../../src/templates/recipe/recipe.js" /* webpackChunkName: "component---src-templates-recipe-recipe-js" */),
  "component---src-templates-recipes-recipes-js": () => import("./../../../src/templates/recipes/recipes.js" /* webpackChunkName: "component---src-templates-recipes-recipes-js" */),
  "component---src-templates-recommendations-recommendations-js": () => import("./../../../src/templates/recommendations/recommendations.js" /* webpackChunkName: "component---src-templates-recommendations-recommendations-js" */),
  "component---src-templates-tools-tools-js": () => import("./../../../src/templates/tools/tools.js" /* webpackChunkName: "component---src-templates-tools-tools-js" */),
  "component---src-templates-video-test-video-test-js": () => import("./../../../src/templates/videoTest/videoTest.js" /* webpackChunkName: "component---src-templates-video-test-video-test-js" */)
}

